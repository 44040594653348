import {
  CapsuleIcon,
  DashboardIcon,
  HeartIcon,
  OrderIcon,
  UserIcon,
  StoreIcon,
} from "assets/images/Images";

const Navigations = [
  {
    label: "dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    label: "uploadPrescription",
    icon: <CapsuleIcon />,
    path: "/upload-prescription",
  },
  {
    label: "requestMedication",
    icon: <HeartIcon />,
    path: "/request-medications",
  },
  {
    label: "Marketplace",
    icon: <StoreIcon />,
    path: "/marketplace",
  },
  {
    label: "myOrders",
    icon: <OrderIcon />,
    path: "/orders",
  },
  {
    label: "myProfile",
    icon: <UserIcon />,
    path: "/my-profile",
  },
  // {
  //   label: 'Products',
  //   icon: <Person />,
  //   path: '/products',
  //   childPath: ['/invoices', '/clients'],  // must have to enter child path here if item has children
  //   children: [
  //     {
  //       label: 'Details',
  //       icon: <Person />,
  //       path: '/invoices'
  //     },
  //     {
  //       label: 'Employees',
  //       icon: <Person />,
  //       path: '/clients'
  //     },
  //   ]
  // },
];

export default Navigations;
