const Colors = {
  primary: "#53B1BF",
  primary1: "#eaf1f7",
  primaryGradient:
    "linear-gradient(to right, #53b1bf, #349db9, #1a88b2, #1773a8, #285d9a)",
  primaryGradient1:
    "linear-gradient(90deg, rgba(235,242,248,1) 42%, rgba(255,255,255,1) 46%, rgba(233,236,239,1) 61%)",
  secondary: "#1F2633",
  secondaryGradient:
    "linear-gradient(to bottom, #212835, #2c333f, #373d49, #424953, #4e545e)",
  tertiary: "#3c86ad",
  tertiaryGradient: "linear-gradient(180deg, #0184B9 0%, #046990 100%)",
  textPrimary: "#B1B4B9",
  textSecondary: "#8c8c8c",
  gunMetal: "#263238",
  black: "#000",
  blackShade: "#17223F",
  smokyBlack: "#0F1A38",
  cyanBlue: "#F6F9FC",
  white: "#ffffff",
  whiteShade: "#ffffff99",
  grey: "#BEBEBE",
  greyShade: "#EFEEE8",
  smokeyGrey: "#d6d7d9",
  desertStorm: "#f7f7f7",
  ghostWhite: "#F5F8FC ",
  flashWhite: "#EFF0F6",
  danger: "#da3633",
  success: "#79AA5A",
  pink: "#f7d7d7",
  pinkshade: "#f7d7d0",
  blueShade: "#3c86ad3d",
  iron: "#E8E8E8",
  grey2: "#868686",

  // primaryGradient: `linear-gradient(to right, #53b1bf, #349db9, #1a88b2, #1773a8, #285d9a)`,
  // primTopDownGradient: `linear-gradient(to bottom, #53b1bf, #349db9, #1a88b2, #1773a8, #285d9a)`,
  quoted: `linear-gradient(to bottom, #212835, #2c333f, #373d49, #424953, #4e545e)`,
  quotedTopDown:
    "linear-gradient(to bottom, #212835, #2c333f, #373d49, #424953, #4e545e)",
  complete:
    "linear-gradient(to right, #53b1bf, #489aa6, #3e848e, #346e77, #2a5960)",
  completeTopDown:
    "linear-gradient(to bottom, #53b1bf, #489aa6, #3e848e, #346e77, #2a5960)",
  accepted:
    "linear-gradient(to right, #4aa3b7, #319ca8, #189596, #058d82, #0a856d)",
  acceptedTopDown:
    "linear-gradient(to bottom, #4aa3b7, #319ca8, #189596, #058d82, #0a856d)",
  confirmed:
    "linear-gradient(to right, #275c98, #225186, #1d4674, #193b62, #153151)",
  confirmTopDown:
    "linear-gradient(to bottom, #275c98, #225186, #1d4674, #193b62, #153151)",
  requested:
    "linear-gradient(to right, #7a7a7a, #5f5e5f, #454345, #2e2a2b, #181313)",
  requestedTopDown:
    "linear-gradient(to bottom, #181313, #2e2a2b, #454345, #5f5e5f, #7a7a7a)",
};

export default Colors;
