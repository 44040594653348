import { useState } from "react";
import AuthServices from "apis/auth/AuthServices";
import Storage from "utils/Storage";

function useProvideAuth() {

  const { setStorageItem, getStorageItem } = Storage();

  // *For User
  const [user, setUser] = useState(getStorageItem('user'));

  // *For Login
  const userLogin = (data) => {
    setStorageItem('user', data)
    setUser(data)
  };

  // *For Logout
  const userLogout = async () => {
    const lang = getStorageItem('lang')
    localStorage.clear()
    setStorageItem('lang', lang)
    setUser(null)
    await AuthServices.logout({ fcm_token: user?.fcm_token })
  };

  // *For Update Profile Image
  const updateProfile = (data) => {
    const userData = getStorageItem('user')
    userData.picture = data.picture
    setStorageItem('user', userData)
    setUser(userData)
  };

  return {
    user,
    userLogin,
    userLogout,
    updateProfile
  };
}

export default useProvideAuth;