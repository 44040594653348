import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from '../translations/english.json';
import arabic from '../translations/arabic.json';

const lang = typeof window !== "undefined" && JSON.parse(localStorage.getItem('lang'))

const resources = {
  en: {
    translation: english,
  },
  ar: {
    translation: arabic,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang ? lang : 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;