import React, { Suspense } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { CircleLoading } from "components/loaders/Loaders";
import { useLanguage } from "context/UseContext";

function WebLayout() {
  const { lang } = useLanguage();

  return (
    <Box dir={lang === "ar" ? "rtl" : "ltr"}>
      {/* ========== Header ========== */}
      <Header />

      {/* ========== Main ========== */}
      <Box component="main">
        <Suspense fallback={<CircleLoading />}>
          <Outlet />
        </Suspense>
      </Box>

      {/* ========== Footer ========== */}
      <Footer />
    </Box>
  );
}

export default WebLayout;
