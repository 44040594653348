import { ArrowForwardIos, Email, Facebook, Instagram, LineStyle, LinkedIn, Padding, Twitter, WhatsApp, YouTube } from '@mui/icons-material'
import { Avatar, Box, Container, Grid, Typography, IconButton, Divider } from '@mui/material'
import AddressServices from 'apis/address/AddressServices'
import Images from 'assets/images/Images'
import Colors from 'assets/style/Colors'
import { ErrorToaster } from 'components/toaster/Toaster'
import { useLanguage } from 'context/UseContext'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment/moment';

function Footer() {
  const { lang } = useLanguage();
  const [settings, setSettings] = useState();
  const { t } = useTranslation();

  const QuickLinks = [{ path: '/', name: 'home' }, { path: '/blog', name: 'blogs' }, { path: '/about-us', name: 'aboutUs' }]

  const [termsConditionsDialog, setTermsConditionsDialog] = useState(false);

  const getSettings = async () => {
    try {
      const { responseCode, data } = await AddressServices.getSettings()
      if (responseCode === 200) {
        setSettings(data.result)
      }
    } catch (error) {
      ErrorToaster(lang === 'ar' ? error?.messageAr : error?.message)
    }
  }
  useEffect(() => {
    getSettings()
  }, []);

  return (
    <Box sx={{
      backgroundColor: Colors.white,
      py: { md: 10, xs: 6 },
      px: { md: '0px', xs: '0px' }
    }}>
      <Container sx={{ px: { md: '24px', xs: '34px' } }} >
        <Grid container spacing={2} sx={{ mb: { md: 4, xs: 2 } }}>
          <Grid item md={5} xs={12} >
            <Typography variant="h5" sx={{ fontSize: { md: '38px', xs: '18px' }, color: Colors.smokyBlack, fontWeight: 500, lineHeight: 1, mb: '28px' }}>
              {t('quickLinks')}
            </Typography>
            <Box component={"ul"} sx={{ p: 0, mb: 0 }}>
              {QuickLinks.map((item, index) => (
                <Box key={index} sx={{ mb: '15px' }}>
                  <Link to={item.path} aria-label={item.name}>
                    <Box component={"li"} sx={{ listStyle: 'none', mb: '24px', }}>
                      <Typography variant="h6" sx={{ fontSize: { md: '24px', xs: '16px' }, color: Colors.grey, fontWeight: 500 }}>
                        {t(item.name)}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h5" sx={{ fontSize: { md: '38px', xs: '18px' }, color: Colors.smokyBlack, fontWeight: 500, lineHeight: 1, mb: '28px' }}>
              {t('contactUs')}
            </Typography>
            <Box component={"ul"} sx={{ p: 0, mb: 0 }}>
              <Box component={"li"} sx={{ listStyle: 'none', mb: { md: '24px', xs: '20px' } }}>
                <IconButton sx={{ p: 0, '&:hover': { background: 'none' } }} >
                  <WhatsApp sx={{
                    fontSize: { xs: '26px', md: '32px' },
                    color: Colors.grey,
                    pr: lang === 'ar' ? 0 : 1,
                    pl: lang === 'ar' ? 1 : 0,
                  }} />
                  <Typography component="a" href={`https://wa.me/${settings?.whatsapp}`} aria-label="whatsapp" target="_blank" rel="noopener noreferrer">
                    <Typography variant="h6" sx={{ fontSize: { md: '24px', xs: '16px' }, color: Colors.grey, fontWeight: 500 }}>
                      {settings?.whatsapp}
                    </Typography>
                  </Typography>
                </IconButton>
              </Box>
              <Box component={"li"} sx={{ listStyle: 'none', mb: { md: '24px', xs: '16px' }, }}>
                <IconButton sx={{ p: 0, '&:hover': { background: 'none' } }} >
                  <Email sx={{
                    fontSize: { xs: '24px', md: '32px' },
                    color: Colors.grey,
                    pr: lang === 'ar' ? 0 : 1,
                    pl: lang === 'ar' ? 1 : 0,
                    pt: '4px',
                  }} />
                  <Typography component="a" href={`mailto:${settings?.email}`} aria-label="email" >
                    <Typography variant="h6" sx={{ fontSize: { md: '24px', xs: '16px' }, color: Colors.grey, fontWeight: 500 }}>
                      {settings?.email}
                    </Typography>
                  </Typography>
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mb: { md: 1, xs: '10px' } }}  >
          <IconButton sx={{ '&:hover': { background: 'none', } }} >
            <Link to={settings?.facebook} aria-label='facebook' target="_blank" rel="noopener noreferrer">
              <Facebook sx={{ fontSize: { md: '30px', xs: '24px' } }} />
            </Link>
          </IconButton>
          <IconButton sx={{ '&:hover': { background: 'none' } }} >
            <Link to={settings?.instagram} aria-label='instagram' target="_blank" rel="noopener noreferrer">
              <Instagram sx={{ fontSize: { md: '30px', xs: '24px' } }} />
            </Link>
          </IconButton>
          <IconButton sx={{ '&:hover': { background: 'none' } }} >
            <Link to={settings?.twiter} aria-label='twitter' target="_blank" rel="noopener noreferrer">
              <Twitter sx={{ fontSize: { md: '30px', xs: '24px' } }} />
            </Link>
          </IconButton>
          <IconButton sx={{ '&:hover': { background: 'none' } }} >
            <Link to={settings?.linkedin} aria-label='linkedin' target="_blank" rel="noopener noreferrer" >
              <LinkedIn sx={{ fontSize: { md: '30px', xs: '24px' } }} />
            </Link>
          </IconButton>
          <IconButton sx={{ '&:hover': { background: 'none' } }} >
            <Link to={settings?.youtube} aria-label='youtube' target="_blank" rel="noopener noreferrer" >
              <YouTube sx={{ fontSize: { md: '30px', xs: '24px' } }} />
            </Link>
          </IconButton>
        </Box>
        <Typography variant="h6" sx={{ fontSize: { md: '22px', xs: '14px' }, color: Colors.grey, mb: '6px' }}>
          Scriptio 2020 - {moment().format('YYYY')} © All rights reserved
        </Typography>
        {/* <Typography variant="h6" sx={{ fontSize: { md: '30px', xs: '18px' }, color: Colors.grey }}>
          disclaimer is at the bottom of the same page of PP/TCs
        </Typography> */}
        <Box sx={{ pt: 1.5, }}>
          <Container>
            <Grid container spacing={0} alignItems="center" justifyContent={"space-between"} >
              <Grid item xs={12} md={12}  >
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center', justifyContent: { md: 'flex-end', xs: 'center' } }}>
                  <Link to="/terms-conditions">
                    <Typography component={"span"} variant="body2" color={Colors.grey} sx={{ fontSize: { md: '18px', xs: '10px' }, mx: 0, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} >
                      {t('termsConditions')} - {t('privacyPolicy')}
                    </Typography>
                  </Link>
                  <Divider orientation="vertical" sx={{ backgroundColor: { md: Colors.grey, xs: Colors.grey }, height: { md: '21px', xs: '14px' }, mx: { md: 1.5, xs: .5 } }} />
                  <Link to="/disclaimer-conditions">
                    <Typography component={"span"} variant="body2" color={Colors.grey} sx={{ fontSize: { md: '18px', xs: '10px' }, mx: 0, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} >
                      {t('Disclaimer')}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </Box >
  )
}

export default Footer

