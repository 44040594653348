import React, { lazy } from "react";

const Dashboard = lazy(() => import("views/dashboard"));
const UploadPrescription = lazy(() => import("views/uploadPrescription"));
const RequestMedications = lazy(() => import("views/requestMedications"));
const Orders = lazy(() => import("views/orders"));
const Profile = lazy(() => import("views/profile"));
const Marketplace = lazy(() => import("../views/marketplace/index"));
const Categories = lazy(() => import("../views/marketplace/categories/index"));
const Pharmacies = lazy(() => import("../views/marketplace/pharmacies/index"));
const Products = lazy(() => import("../views/marketplace/products/index"));
const ProductDetail = lazy(() => import("../views/marketplace/productDetail/index"));
const Cart = lazy(() => import("../views/marketplace/cart/index"));
const Checkout = lazy(() => import("../views/marketplace/checkout/index"));

const DashboardRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/upload-prescription",
    component: <UploadPrescription />,
  },
  {
    path: "/request-medications",
    component: <RequestMedications />,
  },
  {
    path: "/orders",
    component: <Orders />,
  },
  {
    path: "/my-profile",
    component: <Profile />,
  },
  {
    path: "/marketplace",
    component: <Marketplace />,
  },
  {
    path: "/marketplace/categories",
    component: <Categories />,
  },
  {
    path: "/marketplace/pharmacies",
    component: <Pharmacies />,
  },
  {
    path: "/marketplace/products",
    component: <Products />,
  },
  {
    path: "/marketplace/product/:id",
    component: <ProductDetail />,
  },
  {
    path: "/marketplace/cart",
    component: <Cart />,
  },
  {
    path: "/marketplace/checkout",
    component: <Checkout />,
  },
];

export default DashboardRoutes;
