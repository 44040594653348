import { get, patch, post } from "apis";
import ProductRoutes from "./Product.Routes";

const ProductServices = {
  getProducts: async (params) => {
    console.log(params);
    const data = await get(ProductRoutes.getProducts, params);
    return data;
  },
  getProductDetails: async (id) => {
    const data = await get(ProductRoutes.getProductDetails + id);
    return data;
  },
};

export default ProductServices;
