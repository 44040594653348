import React, { Fragment, useState } from "react";
import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Dashboard,
  Logout,
  MenuOutlined,
  Notifications,
  Person,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import Colors from "assets/style/Colors";
import { ErrorToaster, SuccessToaster } from "components/toaster/Toaster";
import { useAuth, useLanguage } from "context/UseContext";
import SelectLanguage from "components/fields/SelectLanguage";
import { ImageAvatar } from "components/avatar/ImageAvatar";
import { onMessageListener } from "../../firebase/firebase";
import NotificationServices from "apis/notification/NotificationServices";
import { CircleLoading } from "components/loaders/Loaders";
import Images from "assets/images/Images";
import { PrimaryButton, SecondaryButton } from "components/buttons/Buttons";
import SimpleDialog from "components/dialog/SimpleDialog";
import OrderServices from "apis/order/OrderServices";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseUrl } from "../../axios";
import { useSelector } from "react-redux";
import OrderDetailDialog from "components/dialog/OrderDetailDialog";
import PaymentDialog from "components/dialog/PaymentDialog";
import moment from "moment";

function DropDown({ anchorEl, openDropdown, handleClose }) {
  const { userLogout } = useAuth();
  const { lang } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // *For Logout
  const logout = () => {
    try {
      userLogout();
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  return (
    <Menu
      dir={lang === "ar" ? "rtl" : "ltr"}
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem onClick={() => navigate("/dashboard")}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        {t("dashboard")}
      </MenuItem>
      <MenuItem onClick={() => navigate("/orders")}>
        <ListItemIcon>
          <ShoppingBag />
        </ListItemIcon>
        {t("myOrders")}
      </MenuItem>
      <MenuItem onClick={() => navigate("/my-profile")}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        {t("myProfile")}
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => logout()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        {t("logout")}
      </MenuItem>
    </Menu>
  );
}

function Header({ openDrawer }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { lang, changeLanguage, languages } = useLanguage();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const { cart } = useSelector((state) => state.cartDataReducer);

  const [notifyAnchor, setNotifyAnchor] = useState(null);
  const openNotificationList = Boolean(notifyAnchor);

  const [loading, setLoading] = useState(false);

  // *For Notification
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  // *For Dialog
  const [orderAcceptDialog, setOrderAcceptDialog] = useState(false);
  const [orderRejectDialog, setOrderRejectDialog] = useState(false);

  // *For Dialog
  const [orderDetailDialog, setOrderDetailDialog] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [orderConfirmDialog, setOrderConfirmDialog] = useState(false);

  // *For Payment Detail
  const [paymentDetail, setPaymentDetail] = useState();

  // *For Delivery Detail
  const [deliveryDetail, setDeliveryDetail] = useState({});

  // *For Order Id
  const [orderId, setOrderId] = useState();

  // *For Get Notifications
  const getNotifications = async () => {
    setLoading(true);
    try {
      const { responseCode, data } =
        await NotificationServices.getNotifications();
      if (responseCode === 200) {
        setNotifications(data.result);
        console.log("🚀 data:", data);
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    } finally {
      setLoading(false);
    }
  };

  // *For Get Notification Count
  const getNotificationCount = async () => {
    try {
      const { responseCode, data } =
        await NotificationServices.getNotificationCount();
      if (responseCode === 200) {
        setNotificationCount(data.unreadCount);
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  // *For Accept/Reject Order
  const acceptRejectOrder = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        status: "accept",
      };
      if (formData?.reason) {
        obj.status = "decline";
        obj.message = formData.reason;
      }
      const { responseCode, message, messageAr } =
        await OrderServices.updateOrderStatus(orderId, obj);
      if (responseCode === 200) {
        if (formData?.reason) {
          setOrderRejectDialog(false);
        } else {
          setOrderAcceptDialog(false);
        }
        SuccessToaster(lang === "ar" ? messageAr : message);
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    } finally {
      setLoading(false);
    }
  };

  //For Get Order Detail by ID
  const getOrderDetailById = async (id) => {
    try {
      setOrderId(id);
      const { responseCode, data } = await OrderServices.getOrderDetailById(id);
      if (responseCode === 200) {
        setOrderDetail(data);
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  const handleOrderDetail = (id) => {
    getOrderDetailById(id);
    setOrderDetailDialog(true);
  };

  // *For Confirm Order By COD
  const confirmOrderByCod = async () => {
    try {
      let obj = {
        tip: paymentDetail?.tip,
        delivery: deliveryDetail,
      };
      const { responseCode, message, messageAr } =
        await OrderServices.confirmOrderByCod(orderId, obj);
      if (responseCode === 200) {
        setOrderConfirmDialog(false);
        SuccessToaster(lang === "ar" ? messageAr : message);
        navigate("/orders");
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  // *For Confirm Order By Card
  const confirmOrderByCard = async () => {
    try {
      let obj = {
        authId: paymentDetail?.authId,
        customerId: paymentDetail?.customerId,
        tip: paymentDetail?.tip,
      };
      const { responseCode, message, messageAr } =
        await OrderServices.confirmOrderByCard(orderId, obj);
      if (responseCode === 200) {
        setOrderConfirmDialog(false);
        SuccessToaster(lang === "ar" ? messageAr : message);
        navigate("/orders");
      }
    } catch (error) {
      ErrorToaster(lang === "ar" ? error?.messageAr : error?.message);
    }
  };

  onMessageListener()
    .then((payload) => {
      getNotificationCount();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Fragment>
      {/* ========== Order Accept Dialog ========== */}
      <SimpleDialog
        open={orderAcceptDialog}
        onClose={() => setOrderAcceptDialog(false)}
        title="orderRequestConfirmation"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            textAlign: "center",
          }}
        >
          <PrimaryButton
            title="yes"
            loading={loading}
            onClick={() => acceptRejectOrder()}
            btnColor={Colors.primaryGradient}
          />
          <Box sx={{ mx: 0.5 }} />
          <SecondaryButton
            title="cancel"
            onClick={() => setOrderAcceptDialog(false)}
          />
        </Box>
      </SimpleDialog>

      {/* ========== Order Reject Dialog Box ========== */}
      <SimpleDialog
        open={orderRejectDialog}
        onClose={() => setOrderRejectDialog(false)}
        title="orderReject"
      >
        <Box component="form" onSubmit={handleSubmit(acceptRejectOrder)}>
          <InputLabel>{t("yourReason")}</InputLabel>
          <InputField
            size={"small"}
            placeholder={"rejectReason"}
            multiline={true}
            rows={3}
            error={errors?.reason?.message}
            register={register("reason", {
              required: "rejectReason",
            })}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              textAlign: "center",
            }}
          >
            <PrimaryButton
              title="proceed"
              type="submit"
              loading={loading}
              btnColor={Colors.primaryGradient}
            />
          </Box>
        </Box>
      </SimpleDialog>

      {/* ========== Payment Dialog ========== */}
      <PaymentDialog
        open={paymentDialog}
        onClose={() => setPaymentDialog(false)}
        orderDetail={orderDetail}
        getOrderDetailById={() => getOrderDetailById(orderId)}
        confirmOrder={(data) => {
          setPaymentDetail(data);
          setOrderConfirmDialog(true);
          setDeliveryDetail(data.delivery);
        }}
      />

      {/* ========== Order Detail Dialog Box ========== */}
      <OrderDetailDialog
        open={orderDetailDialog}
        onClose={() => setOrderDetailDialog(false)}
        orderDetail={orderDetail}
        payment={() => setPaymentDialog(true)}
      />

      {/* ========== Order Confirm Dialog ========== */}
      <SimpleDialog
        open={orderConfirmDialog}
        onClose={() => setOrderConfirmDialog(false)}
        title="orderConfirm"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            textAlign: "center",
          }}
        >
          <PrimaryButton
            title="yes"
            loading={loading}
            onClick={() =>
              paymentDetail?.method === "cod"
                ? confirmOrderByCod()
                : confirmOrderByCard()
            }
            btnColor={Colors.primaryGradient}
          />
          <Box sx={{ mx: 0.5 }} />
          <SecondaryButton
            title="cancel"
            onClick={() => setOrderConfirmDialog(false)}
          />
        </Box>
      </SimpleDialog>

      <AppBar
        elevation={0}
        position="static"
        sx={{
          bgcolor: Colors.white,
          borderRadius: "10px",
          boxShadow: "0px 4px 4px #D7DCE8",
        }}
      >
        <Toolbar>
          {" "}
          {/*sx={{ px: { xl: 6, xs: 1 } }} */}
          <IconButton
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={openDrawer}
          >
            <MenuOutlined sx={{ color: Colors.secondary }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SelectLanguage
              value={lang}
              onSelect={changeLanguage}
              data={languages}
            />
            <IconButton
              aria-label="notification"
              sx={{ mx: { md: 1, sm: 0, xs: 0 }, p: { md: 1, sm: 0, xs: 0 } }}
              onClick={(e) => {
                setNotifyAnchor(e.currentTarget);
                getNotifications();
              }}
            >
              <Badge badgeContent={notificationCount} color="danger">
                <Notifications />
              </Badge>
            </IconButton>

            {/* ========== Notification Dropdown ========== */}
            <Menu
              dir={lang === "ar" ? "rtl" : "ltr"}
              anchorEl={notifyAnchor}
              open={openNotificationList}
              onClose={() => {
                setNotifyAnchor(null);
                setNotificationCount(0);
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: "390px",
                  overflow: "auto",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  borderRadius: "12px",
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <Box sx={{ px: "16px", py: 1 }}>
                <Typography variant="subtitle1">
                  {t("notifications")}
                </Typography>
                {/* {notificationCount > 0 &&
                  <Typography variant="body2">
                    You have {notificationCount} unread messages.
                  </Typography>
                } */}
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box>
                {loading ? (
                  <CircleLoading />
                ) : notifications?.length > 0 ? (
                  notifications.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setNotifyAnchor(null);
                        handleOrderDetail(item?.order_id);
                      }}
                      sx={{
                        borderBottom: `1px solid ${Colors.smokeyGrey + "4D"}`,
                        bgcolor:
                          index >= notificationCount
                            ? "transparent"
                            : Colors.textPrimary + "40",
                        py: 2,
                        my: 0.3,
                        gap: "10px",
                        alignItems: "start",
                        ":hover": {
                          bgcolor:
                            index >= notificationCount
                              ? "transparent"
                              : Colors.textPrimary + "40",
                        },
                      }}
                    >
                      <Box
                        component="img"
                        loading="lazy"
                        src={
                          item?.status?.name === "Cancelled"
                            ? Images.cancelled
                            : item?.status?.name === "Quoted"
                            ? Images.quoted
                            : Images.noti
                        }
                        alt={"notification"}
                        sx={{
                          objectFit: "contain",
                          width: "50px",
                        }}
                      />
                      <Box sx={{ whiteSpace: "break-spaces" }}>
                        <Typography variant="body2">
                          {lang === "ar" ? item.title_ar : item.title}
                        </Typography>
                        {item?.message && (
                          <Typography variant="body2">
                            <b>{t("message")}</b> {item?.message}
                          </Typography>
                        )}
                        {item.createdAt && (
                          <Typography
                            variant="body6"
                            sx={{ color: Colors.grey }}
                          >
                            {moment(item?.createdAt)
                              .locale(lang === "ar" ? "ar" : "en")
                              .format("DD/MM/YYYY, hh:mm A")}
                          </Typography>
                        )}
                        {/* {item?.status?.name === "Quoted" && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              mt: 2,
                              textAlign: "center",
                            }}
                          >
                            <PrimaryButton
                              title="accept"
                              size="small"
                              onClick={() => {
                                setOrderId(item?.order?._id);
                                setOrderAcceptDialog(true);
                              }}
                              btnColor={Colors.primaryGradient}
                            />
                            <Box sx={{ mx: 0.5 }} />
                            <SecondaryButton
                              title="reject"
                              size="small"
                              onClick={() => {
                                setOrderId(item?.order._id);
                                setOrderRejectDialog(true);
                              }}
                            />
                          </Box>
                        )} */}
                        {/* <Typography variant="body2" sx={{ mt: 1 }}>
                            <WatchLater sx={{ color: Colors.smokeyGrey, verticalAlign: 'middle', width: '20px', height: '20px', mr: 0.5 }} />
                            {moment(item?.createdAt).fromNow()}
                          </Typography> */}
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ py: 2, px: 1, textAlign: "center" }}
                  >
                    Your have no notification.
                  </Typography>
                )}
              </Box>
            </Menu>

            <IconButton
              sx={{
                marginRight: { md: "12px", sm: 0, xs: 0 },
                p: { md: 1, sm: 0, xs: 0 },
              }}
              onClick={() => navigate("/marketplace/cart")}
            >
              <Badge badgeContent={cart.length} color="primary">
                <ShoppingCart />
              </Badge>
            </IconButton>

            <Box
              sx={{ cursor: "pointer", ml: { md: 0, sm: 1.5, xs: 1.5 } }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <ImageAvatar image={BaseUrl + user?.picture} />
            </Box>
          </Box>
          <DropDown
            anchorEl={anchorEl}
            openDropdown={openDropdown}
            handleClose={() => setAnchorEl(null)}
          />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default Header;
