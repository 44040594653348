import { get, patch, post } from 'apis';
import AddressRoutes from './Address.Routes';

const AddressServices = {
  getAddressLists: async () => {
    const data = await get(AddressRoutes.getAddressLists);
    return data;
  },
  createAddress: async (obj) => {
    const data = await post(AddressRoutes.createAddress, obj);
    return data;
  },
  updateAddress: async (id, obj) => {
    const data = await patch(AddressRoutes.updateAddress + `?id=${id}`, obj);
    return data;
  },
  deleteAddress: async (id) => {
    const data = await patch(AddressRoutes.deleteAddress + `?id=${id}`);
    return data;
  },
  getSettings: async () => {
    const data = await get(AddressRoutes.getSettings);
    return data;
  },
  getTermsConditions: async () => {
    const data = await get(AddressRoutes.getTermsConditions);
    return data;
  },
  getAbout: async () => {
    const data = await get(AddressRoutes.getAbout);
    return data;
  },
}

export default AddressServices