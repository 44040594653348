import React, { Fragment, useEffect, useState } from 'react';
import { Box, CardMedia, Typography, Grid, Container } from '@mui/material';
import Images from 'assets/images/Images';
import { useTranslation } from 'react-i18next';
import Colors from 'assets/style/Colors';
import { useLanguage } from 'context/UseContext';
import AddressServices from 'apis/address/AddressServices';
import { ErrorToaster } from 'components/toaster/Toaster';

function Disclaimer() {

  const { lang } = useLanguage();
  const { t } = useTranslation();

  // *For Terms and Conditions
  const [terms, setTerms] = useState();

  // *For Get Terms and Conditions
  const getTerms = async () => {
    try {
      const { responseCode, data } = await AddressServices.getTermsConditions()
      if (responseCode === 200) {
        setTerms(data.result)
      }
    } catch (error) {
      ErrorToaster(lang === 'ar' ? error?.messageAr : error?.message)
    }
  }

  useEffect(() => {
    getTerms()
  }, []);

  const currentUrl = window.location.href;

  return (
    <Fragment>

      {/* ========== Banner ========== */}
      <CardMedia image={Images.about}
        sx={{
          width: '100%',
          height: { xs: '150px', md: '300px' },
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          position: 'relative',
          pt: { xs: 16, md: 0 }
        }}
      >
        <Box sx={{ bgcolor: 'rgba(0,0,0,0.4)', position: 'absolute', left: '0px', top: '0px', width: '100%', height: '100%' }} />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            textTransform: 'capitalize',
          }}
        >
          <Typography variant='body1' sx={{ whiteSpace: 'nowrap', color: Colors.white, fontSize: { xs: '36px', md: '52px' }, fontWeight: 700 }}>
            {t('Disclaimer')}
          </Typography>
        </Box>
      </CardMedia>

      <Container>
        <Box sx={{ my: 3, p: { xs: 3, md: 12 }, borderRadius: '10px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant="body3" sx={{ fontSize: '23px', color: Colors.black, lineHeight: 1.50 }} >
                {t('disclaimerDescription')}
              </Typography>
            </Grid>

          </Grid>
        </Box>
      </Container>

    </Fragment>
  );
}

export default Disclaimer;