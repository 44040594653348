const OrderRoutes = {
  uploadPrescription: 'orders/upload',
  createOrder: 'orders/create',
  getOrders: 'orders',
  getOrderDetailById: 'orders/getById',
  updateOrderStatus: 'orders/updatestatus',
  applyVoucher: 'orders/applyvoucher',
  removeVoucher: 'orders/cancelvoucher',
  confirmOrderByCod: 'orders/paymentype/cod',
  confirmOrderByCard: 'orders/paymentype/card',
  confirmPayment: 'orders/paymentype/web/card',
  placeOrderCod: 'orders/place/cod',
  placeOrderCard: 'orders/place/web/card',
};

export default OrderRoutes