import React, { lazy } from 'react';

const ChangePassword = lazy(() => import('views/auth/ChangePassword'));
const ForgotPassword = lazy(() => import('views/auth/ForgotPassword'));
const Login = lazy(() => import('views/auth/Login'));
const Register = lazy(() => import('views/auth/Register'));

const AuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/change-password?",
    component: <ChangePassword />,
  },
]

export default AuthRoutes
