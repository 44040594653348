import { get } from 'apis';
import NotificationRoutes from './Notifications.Routes';

const NotificationServices = {
  getNotifications: async () => {
    const data = await get(NotificationRoutes.getNotifications);
    return data;
  },
  getNotificationCount: async () => {
    const data = await get(NotificationRoutes.getNotificationCount);
    return data;
  },
}

export default NotificationServices