import React from "react";
import { useTranslation } from "react-i18next";
import { Box, FormControl, InputBase, MenuItem, Select, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Colors from "assets/style/Colors";

function SelectLanguage({ value, onSelect, data }) {

  const { t } = useTranslation();

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: Colors.cyanBlue,
      border: 'none',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      padding: '8px 14px',
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

  return (
    <FormControl sx={{ m: 1 }} variant="standard">
      <Select
        value={value}
        onChange={(event) => onSelect(event.target.value)}
        input={<BootstrapInput />}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            <Box
              component="img"
              loading="lazy"
              width="20px"
              src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
              alt={`Flag of ${item.label}`}
              sx={{ mx: 1 }}
            />
            <Typography variant="body2" sx={{ colors: Colors.secondary }}>{t(item.label)}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectLanguage;