import { post } from 'apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  logout: async (obj) => {
    const data = await post(AuthRoutes.logout, obj);
    return data;
  },
  sendOtp: async (obj) => {
    const data = await post(AuthRoutes.sendOtp, obj);
    return data;
  },
  verifyOtp: async (obj) => {
    const data = await post(AuthRoutes.verifyOtp, obj);
    return data;
  },
  uploadImage: async (obj) => {
    const data = await post(AuthRoutes.uploadImage, obj);
    return data;
  },
  resetPasswordOtp: async (obj) => {
    const data = await post(AuthRoutes.resetPasswordOtp, obj);
    return data;
  },
  resetPassword: async (obj) => {
    const data = await post(AuthRoutes.resetPassword, obj);
    return data;
  },
  deactivateAccount: async (obj) => {
    const data = await post(AuthRoutes.deactivateAccount, obj);
    return data;
  },
  changePassword: async (obj) => {
    const data = await post(AuthRoutes.changePassword, obj);
    return data;
  },
}

export default AuthServices