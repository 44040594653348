import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { useLanguage } from 'context/UseContext';
import { useTranslation } from 'react-i18next';

function SimpleDialog({ open, onClose, title, children }) {

  const { lang } = useLanguage()
  const { t } = useTranslation();

  return (
    <Dialog
      dir={lang === 'ar' ? 'rtl' : 'ltr'}
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton color="primary" onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <Close />
      </IconButton>
      <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>{t(title)}</DialogTitle>
      {children}
    </Dialog>
  )
}

export default SimpleDialog