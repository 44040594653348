import React, { lazy } from "react";
import Disclaimer from "views/disclaimer";

const Home = lazy(() => import("views/home"));
const Blog = lazy(() => import("views/blog"));
const ViewBlog = lazy(() => import("views/blog/viewBlog"));
const About = lazy(() => import("views/about"));
const Terms = lazy(() => import("views/terms"));
const WebMarkterPlace = lazy(() => import("views/webMarketplace"));
const WebCategories = lazy(() => import("views/webMarketplace/categories"));
const WebPharmacies = lazy(() => import("views/webMarketplace/pharmacies"));
const WebProducts = lazy(() => import("views/webMarketplace/products"));
const WebProductDetail = lazy(() =>
  import("views/webMarketplace/productDetail")
);
const WebLogin = lazy(() => import("views/auth/WebLogin"));
const WebCheckout = lazy(() => import("views/webMarketplace/checkout"));
const WebRegister = lazy(() => import("views/auth/WebRegister"));

const PublicRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/blog",
    component: <Blog />,
  },
  {
    path: "/view-blog",
    component: <ViewBlog />,
  },
  {
    path: "/about-us",
    component: <About />,
  },

  {
    path: "/privacy-policy",
    component: <Terms />,
  },
  {
    path: "/terms-conditions",
    component: <Terms />,
  },
  {
    path: "/disclaimer-conditions",
    component: <Disclaimer />,
  },
  {
    path: "/web/marketplace",
    component: <WebMarkterPlace />,
  },
  {
    path: "/web/marketplace/categories",
    component: <WebCategories />,
  },
  {
    path: "/web/marketplace/pharmacies",
    component: <WebPharmacies />,
  },
  {
    path: "/web/marketplace/products",
    component: <WebProducts />,
  },
  {
    path: "/web/marketplace/product/:id",
    component: <WebProductDetail />,
  },
  {
    path: "/web/marketplace/checkout",
    component: <WebCheckout />,
  },
  {
    path: "/web/login",
    component: <WebLogin />,
  },
  {
    path: "/web/register",
    component: <WebRegister />,
  },
];

export default PublicRoutes;
