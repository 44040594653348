const AuthRoutes = {
  login: 'auth/login',
  register: 'auth/signup',
  logout: 'auth/logout',
  uploadImage: 'auth/upload',
  sendOtp: 'otp/generate',
  verifyOtp: 'otp/verify',
  resetPasswordOtp: 'otp/resetotp',
  resetPassword: 'otp/reset',
  deactivateAccount: 'user/profile/delete',
  changePassword: 'user/reset/password',
};

export default AuthRoutes